@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

.imgLoader {
  position: fixed;
  -webkit-animation: preLoad 1s steps(1);
  animation: preLoad 1s steps(1);
  width: 1px;
  height: 1px;
}
@-webkit-keyframes preLoad {
  0% {
    background-image: url(./img/page1.png);
  }
  10% {
    background-image: url(./img/page2.png);
  }
  20% {
    background-image: url(./img/page3.png);
  }
  30% {
    background-image: url(./img/page4.jpg);
  }
  40% {
    background-image: url(./img/page5.jpg);
  }
  100% {
    display: none;
  }
}
@keyframes preLoad {
  0% {
    background-image: url(./img/page1.png);
  }
  10% {
    background-image: url(./img/page2.png);
  }
  20% {
    background-image: url(./img/page3.png);
  }
  30% {
    background-image: url(./img/page4.jpg);
  }
  40% {
    background-image: url(./img/page5.jpg);
  }
  100% {
    display: none;
  }
}

.container {
  position: relative;
  /* width: 200px; */
  /* border: rgb(169, 169, 169) solid 2px; */
  /* border-radius: 4px; */
  height: 420px;
}



.book {
  position: absolute;
  perspective: 630px;
  perspective-origin: center 50px;
  transform: scale(1.2);
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
}

.page {
  width: 210px;
  height: 300px;
  background-color: #bbb;
  position: absolute;
  top: 0px;
  right: 50%;
  transform-origin: 100% 100%;
  border: solid #555 2px;
  background-size: 420px 300px;
  background-position: center;
  transform-style: preserve-3d;
}
.page:nth-child(1) {
  transform: rotateX(60deg) rotateY(3deg);
}
.page:nth-child(2) {
  transform: rotateX(60deg) rotateY(4.5deg);
}
.page:nth-child(3) {
  transform: rotateX(60deg) rotateY(6deg);
  -webkit-animation: nextPage 25s infinite -24s steps(1);
  animation: nextPage 25s infinite -24s steps(1);
  background-size: 420px 300px;
  background-position: -2px -2px;
}
.page:nth-child(4) {
  transform: rotateX(60deg) rotateY(177deg);
}
.page:nth-child(5) {
  transform: rotateX(60deg) rotateY(175.5deg);
}
.page:nth-child(6) {
  transform: rotateX(60deg) rotateY(174deg);
  overflow: hidden;
}
.page:nth-child(6)::after {
  content: "";
  width: 210px;
  height: 300px;
  position: absolute;
  top: 0px;
  right: 0%;
  transform-origin: center;
  transform: rotateY(180deg);
  -webkit-animation: nextPage 25s -20s infinite steps(1);
  animation: nextPage 25s -20s infinite steps(1);
  background-size: 420px 300px;
  background-position: 100% -2px;
}
@-webkit-keyframes nextPage {
  0% {
    background-image: url(./img/page1.png);
  }
  20% {
    background-image: url(./img/page2.png);
  }
  40% {
    background-image: url(./img/page3.png);
  }
  60% {
    background-image: url(./img/page4.jpg);
  }
  80% {
    background-image: url(./img/page5.jpg);
  }
}
@keyframes nextPage {
  0% {
    background-image: url(./img/page1.png);
  }
  20% {
    background-image: url(./img/page2.png);
  }
  40% {
    background-image: url(./img/page3.png);
  }
  60% {
    background-image: url(./img/page4.jpg);
  }
  80% {
    background-image: url(./img/page5.jpg);
  }
}

.gap {
  width: 10px;
  height: 300px;
  background: none;
  transform: rotateX(60deg);
  transform-origin: bottom;
  position: absolute;
  top: 0px;
  left: calc(50% - 5px);
}
.gap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #555;
  width: 10px;
  height: 5px;
  border-radius: 50%;
}

.pages,
.flips {
  transform-style: preserve-3d;
}

.flip {
  width: 32px;
  height: 300px;
  position: absolute;
  top: 0px;
  transform-origin: 100% 100%;
  right: 100%;
  border: solid #555;
  border-width: 2px 0px;
  perspective: 4200px;
  perspective-origin: center;
  transform-style: preserve-3d;
  background-size: 420px 300px;
}
.flip::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0%;
  width: 100%;
  height: 100%;
  transform-origin: center;
  background-size: 420px 300px;
}
.flip.flip1 {
  right: 50%;
  -webkit-animation: flip1 5s infinite ease-in-out;
  animation: flip1 5s infinite ease-in-out;
  border-width: 2px 2px 2px 0;
}
.flip.flip1::after {
  -webkit-animation: nextFlip1 25s -20s infinite steps(1);
  animation: nextFlip1 25s -20s infinite steps(1);
}
.flip:not(.flip1) {
  right: calc(100% - 2px);
  top: -2px;
  transform-origin: right;
  -webkit-animation: flip2 5s ease-in-out infinite;
  animation: flip2 5s ease-in-out infinite;
}
.flip.flip2::after {
  -webkit-animation: nextFlip2 25s -20s infinite steps(1);
  animation: nextFlip2 25s -20s infinite steps(1);
}
.flip.flip3::after {
  -webkit-animation: nextFlip3 25s -20s infinite steps(1);
  animation: nextFlip3 25s -20s infinite steps(1);
}
.flip.flip4::after {
  -webkit-animation: nextFlip4 25s -20s infinite steps(1);
  animation: nextFlip4 25s -20s infinite steps(1);
}
.flip.flip5::after {
  -webkit-animation: nextFlip5 25s -20s infinite steps(1);
  animation: nextFlip5 25s -20s infinite steps(1);
}
.flip.flip6::after {
  -webkit-animation: nextFlip6 25s -20s infinite steps(1);
  animation: nextFlip6 25s -20s infinite steps(1);
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
  animation: nextFlip7 25s -20s infinite steps(1);
}
.flip.flip7 {
  width: 30px;
  border-width: 2px 0px 2px 2px;
}
.flip.flip7::after {
  -webkit-animation: nextFlip7 25s -20s infinite steps(1);
  animation: nextFlip7 25s -20s infinite steps(1);
}
@-webkit-keyframes flip1 {
  0%,
  20% {
    transform: rotateX(60deg) rotateY(6deg);
  }
  80%,
  100% {
    transform: rotateX(60deg) rotateY(174deg);
  }
}
@keyframes flip1 {
  0%,
  20% {
    transform: rotateX(60deg) rotateY(6deg);
  }
  80%,
  100% {
    transform: rotateX(60deg) rotateY(174deg);
  }
}
@-webkit-keyframes flip2 {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
}
@keyframes flip2 {
  0%,
  20% {
    transform: rotateY(0deg) translateY(0px);
  }
  50% {
    transform: rotateY(-15deg) translateY(0px);
  }
}

@-webkit-keyframes nextFlip1 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  10% {
    background-image: url(./img/page2.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  30% {
    background-image: url(./img/page3.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  50% {
    background-image: url(./img/page4.jpg);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  70% {
    background-image: url(./img/page5.jpg);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  90% {
    background-image: url(./img/page1.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
}

@keyframes nextFlip1 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  10% {
    background-image: url(./img/page2.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  30% {
    background-image: url(./img/page3.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  50% {
    background-image: url(./img/page4.jpg);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  70% {
    background-image: url(./img/page5.jpg);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -178px -2px;
    transform: rotateY(0deg);
  }
  90% {
    background-image: url(./img/page1.png);
    background-position: -210px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip2 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  10.5% {
    background-image: url(./img/page2.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  30.5% {
    background-image: url(./img/page3.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  50.5% {
    background-image: url(./img/page4.jpg);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  70.5% {
    background-image: url(./img/page5.jpg);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  90.5% {
    background-image: url(./img/page1.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip2 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  10.5% {
    background-image: url(./img/page2.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  30.5% {
    background-image: url(./img/page3.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  50.5% {
    background-image: url(./img/page4.jpg);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  70.5% {
    background-image: url(./img/page5.jpg);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -148px -2px;
    transform: rotateY(0deg);
  }
  90.5% {
    background-image: url(./img/page1.png);
    background-position: -238px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip3 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  11% {
    background-image: url(./img/page2.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  31% {
    background-image: url(./img/page3.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  51% {
    background-image: url(./img/page4.jpg);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  71% {
    background-image: url(./img/page5.jpg);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  91% {
    background-image: url(./img/page1.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip3 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  11% {
    background-image: url(./img/page2.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  31% {
    background-image: url(./img/page3.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  51% {
    background-image: url(./img/page4.jpg);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  71% {
    background-image: url(./img/page5.jpg);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -118px -2px;
    transform: rotateY(0deg);
  }
  91% {
    background-image: url(./img/page1.png);
    background-position: -268px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip4 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  11.5% {
    background-image: url(./img/page2.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  31.5% {
    background-image: url(./img/page3.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  51.5% {
    background-image: url(./img/page4.jpg);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  71.5% {
    background-image: url(./img/page5.jpg);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  91.5% {
    background-image: url(./img/page1.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip4 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  11.5% {
    background-image: url(./img/page2.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  31.5% {
    background-image: url(./img/page3.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  51.5% {
    background-image: url(./img/page4.jpg);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  71.5% {
    background-image: url(./img/page5.jpg);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -88px -2px;
    transform: rotateY(0deg);
  }
  91.5% {
    background-image: url(./img/page1.png);
    background-position: -298px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip5 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  12% {
    background-image: url(./img/page2.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  32% {
    background-image: url(./img/page3.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  52% {
    background-image: url(./img/page4.jpg);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  72% {
    background-image: url(./img/page5.jpg);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  92% {
    background-image: url(./img/page1.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip5 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  12% {
    background-image: url(./img/page2.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  32% {
    background-image: url(./img/page3.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  52% {
    background-image: url(./img/page4.jpg);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  72% {
    background-image: url(./img/page5.jpg);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -58px -2px;
    transform: rotateY(0deg);
  }
  92% {
    background-image: url(./img/page1.png);
    background-position: -328px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip6 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  12.5% {
    background-image: url(./img/page2.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  32.5% {
    background-image: url(./img/page3.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  52.5% {
    background-image: url(./img/page4.jpg);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  72.5% {
    background-image: url(./img/page5.jpg);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  92.5% {
    background-image: url(./img/page1.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip6 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  12.5% {
    background-image: url(./img/page2.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  32.5% {
    background-image: url(./img/page3.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  52.5% {
    background-image: url(./img/page4.jpg);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  72.5% {
    background-image: url(./img/page5.jpg);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -28px -2px;
    transform: rotateY(0deg);
  }
  92.5% {
    background-image: url(./img/page1.png);
    background-position: -358px -2px;
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes nextFlip7 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  13% {
    background-image: url(./img/page2.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  33% {
    background-image: url(./img/page3.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  53% {
    background-image: url(./img/page4.jpg);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  73% {
    background-image: url(./img/page5.jpg);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  93% {
    background-image: url(./img/page1.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
}
@keyframes nextFlip7 {
  0% {
    background-image: url(./img/page1.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  13% {
    background-image: url(./img/page2.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  20% {
    background-image: url(./img/page2.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  33% {
    background-image: url(./img/page3.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  40% {
    background-image: url(./img/page3.png);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  53% {
    background-image: url(./img/page4.jpg);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  60% {
    background-image: url(./img/page4.jpg);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  73% {
    background-image: url(./img/page5.jpg);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
  80% {
    background-image: url(./img/page5.jpg);
    background-position: -2px -2px;
    transform: rotateY(0deg);
  }
  93% {
    background-image: url(./img/page1.png);
    background-position: -388px -2px;
    transform: rotateY(180deg);
  }
}
.twitterLink {
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
}
.twitterLink img {
  width: 2em;
  filter: grayscale(100%);
  transition: filter 0.25s;
}
.twitterLink img:hover {
  filter: grayscale(0%);
}
.album { 
  margin: auto;
    /* margin-left: 275px;
    margin-right: 275px;  */
    /* width: 100px !important;
    display: flex; 
    padding-top: 100px; 
    padding-right: 250px; 
    padding-left: 250px; 
    padding-bottom: 100px;   */
 }
.custom-btn {
    width: 130px;
    height: 40px;
    color: #fff;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .btn-3 {
    background: rgb(0, 172, 238);
    background: linear-gradient(
      0deg,
      rgba(0, 172, 238, 1) 0%,
      rgba(2, 126, 251, 1) 100%
    );
    width: 130px;
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
  }